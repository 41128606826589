<template>
  <div class="user_register_completed">
    <div class="user_register_completed__body">
      <div class="user_register_completed__body__top mw-1000">
        <div class="user_register_completed__body__top__step">
          <span>
            会員登録
          </span>
          <span>
            基本情報登録
          </span>
          <span>
            基本情報確認
          </span>
          <span class="active">
            利用登録完了
          </span>
        </div>
        <p class="user_register_completed__body__top-title">
          マナビDX Quest 利用登録完了
        </p>
      </div>
      <div class="mw-1000">
        <div class="user_register_completed__body__form">
          <form class="horizontal" @submit="goDashboard">
            <div class="user_register_completed__body__form-infomation">
              <p>マナビDX Quest利用登録が完了しました。</p>
              <br />
              <br />
              <br />
              <p>続いてあなたのPR・プロフィールを入力してください。</p>
              <br />
              <br />
              <p>
                あとで入力することもできますが、はじめに入力しておくと様々なクライアント企業から注目されやすくなります。ぜひ、ご入力をおすすめします。
              </p>
            </div>
            <div class="btn_group">
              <button type="submit" :disabled="isSubmitting" class="btn-md btn-blue shadow">
                <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                ダッシュボードへ
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/register/completed/Completed.ts"></script>

<style lang="scss">
@import "@/presentation/views/user/register/completed/Completed.scss";
</style>

import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import routePath from "@/commons/RoutePath";
import AuthInteracter from "@/domain/usecases/AuthInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
export default defineComponent({
    name: "UserRegisterCompleted",
    components: {
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var _a = useForm(), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit;
        var router = useRouter();
        var store = useStore();
        var authInteractor = DIContainer.instance.get(AuthInteracter);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: routePath.TOP,
                    name: "TOP"
                },
                {
                    route: "",
                    name: "会員登録完了"
                }
            ],
        });
        var goDashboard = handleSubmit(function () {
            return authInteractor.authCheck().then(function (result) {
                if (result.data && result.data.user && result.data.user.nick_name) {
                    var account_name = result.data.user.nick_name
                        ? result.data.user.nick_name
                        : result.data.organization.name;
                    localStorage.setItem("accountName", account_name);
                }
                var mode = "userLogged";
                store.commit("setHeaderMode", mode);
                router.push(routePath.USER_DASHBOARD);
            }).catch(function (error) {
            });
        });
        onMounted(function () {
            window.history.pushState(null, "", null);
            window.onpopstate = function () {
                window.history.pushState(null, "", null);
            };
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            goDashboard: goDashboard
        };
    }
});
